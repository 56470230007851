export const accept = `### <h2 align="center">СОГЛАСИЕ на обработку персональных данных</h2> 
  
Пользователь, оставляя обращение, заявку на сайте https://lawsuitgroup.ru (далее также – сайт), создавая аккаунт и/или соглашаясь с офертой на сайте, принимает настоящее Согласие на обработку персональных данных.
Пользователь, действуя свободно, своей волей и в своём интересе, подтверждая свою дееспособность, даёт своё согласие центру юридической помощи «Согласие» на обработку своих персональных данных как с использованием, так и без использования средств автоматизации для целей обработки входящих запросов физических лиц (пользователей) с целью консультирования, направления комментариев физическим лицам (пользователям); аналитики действий физического лица (пользователя) на сайте и функционирования сайта; выполнения обязательств по договору оферты, принятому пользователем на сайте. При этом:

1.\tСогласие предоставлено для использования моих следующих персональных данных: Фамилия, имя, отчество; номера контактных телефонов; адреса электронной почты; место работы и занимаемая должность; адрес; сведения о местоположении; тип, версия, язык операционной системы, браузера; тип устройства и разрешение его экрана; страницы, открываемые пользователем; ip-адрес.

2.\tОбработка моих персональных данных может включать следующие действия: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение.

Настоящее согласие может быть отозвано путём направления субъектом персональных данных (пользователем) или его представителем письменного заявления по адресу: 119146, город Москва, Комсомольский пр-кт, д. 32 к. 2, этаж 1 помещ. XVIII ком. 5 офис 04-2 либо по адресу электронной почты: адрес электронной почты компании. В случае отзыва субъектом персональных данных (пользователем) согласия на обработку персональных данных ООО «Бастион» вправе продолжить обработку таких персональных данных в случаях, предусмотренных пунктами 2-11 части 1 статьи 6, пунктами 2-10 части 2 статьи 10, части 2 статьи 11 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
Настоящее согласие предоставляется на неопределённый срок и действует весь период обработки персональных данных. Запросы относительно персональных данных могут быть направлены по электронному адресу: go09997@mail.ru
`;
