export const terms = `### <h2 align="center">Положение об обработке персональных данных</h2> 

### <h3 align="center">1.ОБЩИЕ ПОЛОЖЕНИЯ</h3>
1.\tНастоящее Положение об обработке персональных данных (далее – Положение, настоящее Положение) разработано центром юридической помощи «Согласие» (далее также – Оператор) и применяется в соответствии с п. 2 ч. 1 ст. 18.1. Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных». Настоящее Положение определяет политику Оператора в отношении обработки персональных данных.
Все вопросы, связанные с обработкой персональных данных, не урегулированные настоящим Положением, разрешаются в соответствии с действующим законодательством Российской Федерации в области персональных данных.Настоящее Положение и изменения к нему утверждаются руководителем Оператора и вводятся приказом Оператора.
2.\tВ соответствии с п. 1 ст. 3 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» под персональными данными клиентов, физических лиц понимается любая информация, относящаяся к прямо или косвенно определённому или определяемому на основании такой информации клиенту, физическому лицу (далее – персональные данные).
3.\tЦентр Юридической помощи «Согласие» является оператором, организующим и (или) осуществляющим обработку персональных данных, а также определяющим цели и содержание обработки персональных данных.
4.\tЦелью обработки персональных данных является:
 -\tобеспечение защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну;
 -\tоказание Оператором физическим и юридическим лицам услуг, связанных с хозяйственной деятельностью Оператора, включая контакты Оператора с такими лицами, в том числе по электронной почте, по телефону, по адресу, предоставленным соответствующим лицом;
 -\tнаправление консультаций, ответов обратившимся лицам с помощью средств связи и указанных ими контрактных данных;
 -\tпродвижение товаров, работ, услуг Оператора на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи (допускается только при условии предварительного согласия субъекта персональных данных).
5.\tОбработка организована Оператором на принципах:
 -\tзаконности целей и способов обработки персональных данных, добросовестности и справедливости в деятельности Оператора;
 -\tдостоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;
 -\tобработки только персональных данных, которые отвечают целям их обработки;
 -\tсоответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки;
 -\tнедопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, не совместимых между собой;
 -\tобеспечения точности персональных данных, их достаточности, а в необходимых случаях и актуальности по отношению к целям обработки персональных данных. Оператор принимает необходимые меры либо обеспечивает их принятие по удалению или уточнению неполных или неточных данных;
 -\tхранения персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных.
6.\tОбработка персональных данных осуществляется с соблюдением принципов и правил, предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» и настоящим Положением.
7.\tПерсональные данные обрабатываются с использованием и без использования средств автоматизации.
8.\tВ соответствии с поставленными целями и задачами Оператор до начала обработки персональных данных назначает ответственного за организацию обработки персональных данных.
 -\tОтветственный за организацию обработки персональных данных получает указания непосредственно от исполнительного органа Оператора и подотчетен ему.
 -\tОтветственный за организацию обработки персональных данных вправе оформлять и подписывать уведомление, предусмотренное ч. 1 и 3 ст. 22 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
9.\tСотрудники Оператора, непосредственно осуществляющие обработку персональных данных, должны быть ознакомлены до начала работы с положениями законодательства Российской Федерации о персональных данных, в том числе с требованиями к защите персональных данных, документами, определяющими политику Оператора в отношении обработки персональных данных, локальными актами по вопросам обработки персональных данных, с данным Положением и изменениями к нему.
10.\tПри обработке персональных данных Оператор применяет правовые, организационные и технические меры по обеспечению безопасности персональных данных в соответствии со ст. 19 Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных».
11.\tПри осуществлении сбора персональных данных с использованием информационно-телекоммуникационных сетей Оператор обязан опубликовать в соответствующей информационно-телекоммуникационной сети документ, определяющий его политику в отношении обработки персональных данных, и сведения о реализуемых требованиях к защите персональных данных, а также обеспечить возможность доступа к указанному документу с использованием средств соответствующей информационно-телекоммуникационной сети.
12.\tУсловия обработки персональных данных Оператором. Обработка персональных данных допускается в следующих случаях:
 -\tобработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;
 -\tобработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на Оператора функций, полномочий и обязанностей;
 -\tобработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, в том числе в случае реализации Оператором своего права на уступку прав (требований) по такому договору, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;
 -\tобработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных, если получение согласия субъекта персональных данных невозможно;
 -\tобработка персональных данных необходима для осуществления прав и законных интересов Оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;
 -\tобработка персональных данных осуществляется в статистических или иных исследовательских целях, за исключением целей, указанных в ст. 15 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», при условии обязательного обезличивания персональных данных;
 -\tосуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе;
 -\tосуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.
13.\tХранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели их обработки, и они подлежат уничтожению по достижении целей обработки или в случае утраты необходимости в их достижении в порядке, предусмотренном Положением о хранении персональных данных у Оператора.
14.\tПерсональные данные, которые обрабатываются в информационных системах, подлежат защите от несанкционированного доступа и копирования. Безопасность персональных данных при их обработке в информационных системах обеспечивается с помощью системы защиты персональных данных, включающей организационные меры и средства защиты информации. Технические и программные средства должны удовлетворять устанавливаемым в соответствии с законодательством Российской Федерации требованиям, обеспечивающим защиту информации.
15.\tВзаимодействие с федеральными органами исполнительной власти по вопросам обработки и защиты персональных данных субъектов, персональные данные которых обрабатываются Оператором, осуществляется в рамках законодательства Российской Федерации.

### <h3 align="center">ОБЕСПЕЧЕНИЕ ОПЕРАТОРОМ ПРАВ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3> 
1.\tСубъекты персональных данных или их представители обладают правами, предусмотренными Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» и другими нормативно-правовыми актами, регламентирующими обработку персональных данных.
2.\tОператор обеспечивает права субъектов персональных данных в порядке, установленном главами 3 и 4 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
3.\tОператор обязан предоставить безвозмездно субъекту персональных данных или его представителю возможность ознакомления с персональными данными, относящимися к этому субъекту персональных данных, по месту расположения Оператора в рабочее время Оператора.
4.\tПраво субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами.
5.\tВ случае представления интересов субъекта персональных данных представителем полномочия представителя подтверждаются доверенностью, оформленной в установленном порядке.
6.\tВ случаях предоставления субъектом персональных данных письменного согласия на использование персональных данных для такого согласия достаточно простой письменной формы.
7.\tОператор гарантирует безопасность и конфиденциальность используемых персональных данных.
8.\tОбработка персональных данных в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи допускается только при условии предварительного согласия субъекта персональных данных.

### <h3 align="center">ПОЛУЧЕНИЯ, ОБРАБОТКА, ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3> 
1.\tУ Оператора устанавливается следующий порядок получения персональных данных:
 -\tПри обращении за получением услуг Оператора клиент указывает установленные соответствующими формами данные.
 -\tОператор не получает и не обрабатывает персональные данные клиента о его расовой принадлежности, политических взглядах, религиозных и философских убеждениях, состоянии здоровья, интимной жизни, если законом не предусмотрено иное.
 -\tВ случаях, непосредственно связанных с вопросами трудовых отношений, в соответствии со ст. 24 Конституции Российской Федерации Организация вправе получать и обрабатывать данные о частной жизни клиента только с его письменного согласия.
2.\tВ случае принятия клиентом оферты, размещённой на сайте Оператора, либо заключения другого договора с Оператором обработка персональных данных клиента осуществляется для исполнения соответствующего договора, вступившего в силу вследствие принятия условий оферты клиентом либо заключения другого договора соответственно.
3.\tТакже Оператор вправе обрабатывать персональные данные клиентов, обратившихся к Оператору физических лиц только с их согласия на использование персональных данных.
4.\tСогласие клиента на обработку персональных данных не требуется в следующих случаях:
 -\tперсональные данные являются общедоступными;
 -\tобработка персональных данных осуществляется на основании федерального закона, устанавливающего ее цель, условия получения персональных данных и круг субъектов, персональные данные которых подлежат обработке, а также определенного полномочия Организации;
 -\tпо требованию полномочных государственных органов - в случаях, предусмотренных федеральным законом;
 -\tобработка персональных данных в целях исполнения договора, заключённого с Оператором;
 -\tобработка персональных данных осуществляется для статистических или иных научных целей при условии обязательного обезличивания персональных данных;
 -\tобработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов клиента, если получение его согласия невозможно.
5.\tОператор обеспечивает безопасное хранение персональных данных, в том числе:
 -\tХранение, комплектование, учет и использование содержащих персональные данные документов организуется в форме обособленного архива Оператора.
 -\tХранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.

### <h3 align="center">ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3> 
1.\tПерсональные данные передаются с соблюдением следующих требований:
 -\tзапрещается сообщать персональные данные третьей стороне без письменного согласия клиента, за исключением случаев, когда это необходимо в целях предупреждения угрозы жизни, здоровью клиента, а также в других случаях, предусмотренных законами;
 -\tне сообщать персональные данные в коммерческих целях без письменного согласия субъекта таких данных;
 -\tпредупредить лиц, получающих персональные данные, о том, что эти данные могут быть использованы лишь в целях, для которых они сообщены, и требовать от этих лиц подтверждения того, что это правило соблюдено;
 -\tразрешать доступ к персональным данным только специально уполномоченным лицам, при этом указанные лица должны иметь право получать только те персональные данные, которые необходимы для выполнения конкретных функций;
 -\tне запрашивать информацию о состоянии здоровья клиента, за исключением тех сведений, которые относятся к вопросу о возможности выполнения клиентом обязательств по договору с Оператором;
 -\tпередавать персональные данные клиента его представителям в порядке, установленном Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».

### <h3 align="center">ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ</h3> 
1.\tПраво доступа к персональным данным имеют:
 -\tруководитель Оператора;
 -\tработающие с определённым клиентом работники Оператора;
 -\tработники бухгалтерии;
 -\tработники, осуществляющие техническое обеспечение деятельности Оператора.
2.\tКлиенты в целях обеспечения защиты персональных данных имеют следующие права:
 -\tна полную информацию об их персональных данных и обработке этих данных;
 -\tна свободный бесплатный доступ к своим персональным данным, включая право на получение копий любой записи, содержащей персональные данные, за исключением случаев, предусмотренных федеральным законом;
 -\tна определение своих представителей для защиты своих персональных данных;
 -\tна требование об исключении или исправлении неверных или неполных персональных данных, а также данных, обработанных с нарушением требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
3.\tКопировать и делать выписки персональных данных разрешается исключительно в служебных целях с разрешения руководителя.

### <h3 align="center">ОТВЕТСТВЕННОСТЬ ЗА НАРУШЕНИЕ НОРМ, РЕГУЛИРУЮЩИХ ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3> 
1.\tЛица, виновные в нарушении порядка обращения с персональными данными, несут дисциплинарную, административную, гражданско-правовую или уголовную ответственность в соответствии с федеральными законами.
2.\tРуководители структурных подразделений Оператора несут персональную ответственность за исполнение обязанностей их подчиненными.
`;
