import { DialogContentText } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Refund from '../assets/refund.png';
import Money from '../assets/moneyBack.jpeg';

const List = styled.ul`
  text-align: start;
  margin: 0;
`;

const Li = styled.li``;

export const fraudCases = [
  {
    id: 1,
    title: 'Возврат денежных средств с КПК, инвестиционных компаний и брокеров',
    paragraph: (
      <>
        <DialogContentText sx={{ color: '#000', marginBottom: '16px' }}>
          <strong>
            Наши специалисты специализируются на возврате вкладов и процентов по договору.
          </strong>
        </DialogContentText>
        <DialogContentText sx={{ color: '#000', marginBottom: '16px', textIndent: '32px' }}>
          Целевой аудиторией КПК, инвестиционных компаний и брокеров являются люди старшего
          поколения и пенсионеры. Как правило, данные организации привлекают своих клиентов с
          помощью обещаний высоких процентных доходов по вкладам. Однако спустя определенное время
          исчезают, закрываются и банкротятся. А клиенты (пайщики, инвесторы) в итоге остаются не
          только без обещанных процентов, но и рискуют в итоге потерять все вложенные средства.
        </DialogContentText>
        <DialogContentText sx={{ color: '#000', marginBottom: '16px', textIndent: '32px' }}>
          Зачастую подобные организации оказываются обыкновенными финансовыми пирамидами. Принцип их
          работы заключается в том, что первое время своего существования они исправно выплачивают
          вкладчикам обещанную доходность. Таким образом, они вызывают доверие у клиентов, которые
          вкладывают больше средств, делают им рекламу и приводят новых клиентов. После этого
          выплаты процентов приостанавливают или сокращают. В конечном итоге сотрудники перестают
          отвечать на звонки, а офис компании закрывается. Компания объявляет себя банкротом, а
          через какое-то время открывается в новом месте под другим названием.
        </DialogContentText>
        <DialogContentText sx={{ color: '#000', marginBottom: '16px', textIndent: '32px' }}>
          Если Вы вложили деньги в подобную компанию, у Вас есть право подавать исковое заявление о
          возврате вложенных средств и процентов, предусмотренных договором!
        </DialogContentText>
        <DialogContentText sx={{ color: '#000', marginBottom: '16px', textIndent: '32px' }}>
          Для успешного возврата Ваших средств, настоятельно рекомендуем обратиться к грамотному
          юристу, который специализируется на данных вопросах.
        </DialogContentText>
      </>
    ),
    image: Refund,
  },
  {
    id: 2,
    title: 'Взыскание долгов по расписке или договору займа',
    paragraph: (
      <>
        <DialogContentText sx={{ color: '#000', marginBottom: '16px' }}>
          <strong>Взыскание долга по расписке.</strong>
        </DialogContentText>

        <DialogContentText sx={{ color: '#000', marginBottom: '16px', textIndent: '32px' }}>
          У всех в жизни бывают ситуации, когда родственники, друзья или знакомые просят занять
          деньги в долг. Многие люди при этом пишут расписку «от руки», в которой указывают, что
          взяли в долг определенную сумму на определенный срок.
        </DialogContentText>

        <DialogContentText sx={{ color: '#000', marginBottom: '16px', textIndent: '32px' }}>
          Однако, к сожалению, подобные расписки не всегда обеспечивают возврат Ваших денег в
          случае, если заемщик отказывается возвращать долг. В таком случае основным способ возврата
          денег является подача заявления в суд.
        </DialogContentText>

        <strong>Взыскание задолженности по договору займа с физического лица.</strong>
        <br />
        <br />
        <DialogContentText sx={{ color: '#000', marginBottom: '16px', textIndent: '32px' }}>
          Альтернативой расписке зачастую выступает договор займа. Помощь юриста по возврату
          денежных средств по договору займа с физическим лицом в принудительном порядке необходима,
          когда заемщик не вернул полученные денежные средства, либо вернул не в полном объеме.
          Возврат денежных средств по договору займа производится одним из следующих способов:
        </DialogContentText>

        <List>
          <Li>Путем внесудебного урегулирования проблемы с долгом;</Li>
          <Li>Путем подачи искового заявления в суд о взыскании задолженности.</Li>
        </List>

        <DialogContentText sx={{ color: '#000', marginBottom: '16px', textIndent: '32px' }}>
          Несмотря на то, что суды часто занимают сторону займодавцев, которые одолжили деньги
          нечестным должникам, процесс подготовки всех необходимых документов (заявления,
          ходатайств, подтверждающих документов) и представление интересов в суде имеет множество
          нюансов и тонкостей, от которых зависит успех разбирательства. Именно поэтому мы
          настоятельно рекомендуем доверить решение Вашего вопроса профессионалам, обладающим
          необходимой квалификацией и большим опытом в вопросе взыскания задолженности.
        </DialogContentText>
      </>
    ),
    image: Money,
  },
];

export const fraudExamples = [
  'Гражданин Н. отдал в займ ООО «Домашние деньги» денежные средства, однако долг ему не возвратили. Наш специалист подготовил исковое заявление и успешно представил интересы Н. в суде, в результате чего в пользу клиента взыскана сумма в размере 11 084 241, 8 рублей',
  'Гражданка О. купила акции ПАО «Соль Руси» на 750 000 рублей с обещанной доходностью до 30%, однако деньги гражданке вместе с обещанной доходностью не вернули, а компания оказалась банкротом. Наш специалист подготовил исковое заявление и успешно представил интересы О. в суде, в результате чего в пользу клиента взыскана сумма в размере 1 237 000 рублей',
  'Гражданин А. обратилась в суд с иском о взыскании убытков, компенсации морального вреда и взыскании судебных расходов, однако суды первой и апелляционной инстанции отказали в удовлетворении требований. Наш специалист помог составить кассационную жалобу и защитил интересы клиента в суде, благодаря чему клиент получил денежные средства в размере более 2 000 000 рублей',
  'Гражданин Д. вложил денежные средства под 13,5% годовых  в ООО «Капитал+», но в назначенное время денежные средства гражданину возвращены не были. Наш специалист подготовил исковое заявление и успешно представил интересы Д. в суде, в результате чего в пользу клиента взыскана сумма в размере 3 200 000 рублей',
];
